import {
	Button,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Select,
	SvgIcon,
	Switch,
	Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '@mui/material'
import { DatePicker, PickersDay } from '@mui/x-date-pickers'
import { AngleLeftFal, AngleRightFal } from '@oliverit/react-fontawesome'
import { default as i18n } from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { getSuggestedDate } from '../../../../utils/appointment'

const styleSheet = {
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	dateSelection: {
		marginTop: 16,
		marginBottom: 16
	},
	dateSelectionPicker: {
		flex: 1
	},
	dateSelectionRow: {
		display: 'flex',
		alignItems: 'center',
		flex: 1
	},
	dateSelectionLoading: {
		marginLeft: 8
	},
	dateSelectionEmpty: {
		flex: 1
	},
	dateSelectionDefault: {
		flex: 1
	},
	dateSelectioWarning: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		marginTop: 8
	},
	dateSelectioWarningIcon: {
		marginRight: 8
	},
	timeSelection: {
		marginTop: 8,
		marginBottom: 8
	},
	confirmButton: {
		marginTop: 16
	},
	ratingChip: {
		marginLeft: 8
	}
}

class DeliveryDate extends PureComponent {
	static propTypes = {
		appointment: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
		dateProposal: PropTypes.object,
		dateProposalLoading: PropTypes.bool,
		order: PropTypes.object.isRequired,
		getProposal: PropTypes.func.isRequired,
		setDeliveryDate: PropTypes.func.isRequired,
		timeSlots: PropTypes.array.isRequired,
		tourDate: PropTypes.string,
		unavailableProposalMomentsForOrder: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		const suggestedDate = getSuggestedDate(props.order)
		let appointmentDate = null
		if (props.appointment && props.appointment.deliveryDate) {
			appointmentDate = moment.tz(
				props.appointment.deliveryDate.from,
				'Europe/Amsterdam'
			)
		}

		let selectedTimeFromHour = 7
		let selectedTimeFromMinute = 0
		let selectedTimeTillHour = 17
		let selectedTimeTillMinute = 0
		let selectedTimeSlot = { from: '' }

		// Automatically select time slot if there is only one
		const availableTimeSlotsForSelectedDate = props.timeSlots.filter(
			(availableDate) =>
				moment(availableDate.from).isSame(
					appointmentDate || suggestedDate,
					'day'
				)
		)
		if (availableTimeSlotsForSelectedDate.length === 1) {
			const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
			selectedTimeSlot = {
				from: firstTimeSlot.from,
				till: firstTimeSlot.till
			}
			selectedTimeFromHour = moment(firstTimeSlot.from).hour()
			selectedTimeFromMinute = moment(firstTimeSlot.from).minute()
			selectedTimeTillHour = moment(firstTimeSlot.till).hour()
			selectedTimeTillMinute = moment(firstTimeSlot.from).minute()
		}

		// Check if the appointment date is available according to the time slots (MuleSoft)
		const isAppointmentDateAvailable = appointmentDate
			? props.timeSlots.some((availableDate) =>
					moment(availableDate.from).isSame(appointmentDate, 'day')
			  )
			: false

		// Check if the suggested date is available according to the time slots (MuleSoft)
		const isSuggestedDateAvailable = props.timeSlots.some((availableDate) =>
			moment(availableDate.from).isSame(suggestedDate, 'day')
		)

		// Select the date to show in the date picker as initial value
		const selectedDate = isAppointmentDateAvailable
			? appointmentDate
			: isSuggestedDateAvailable
			? suggestedDate
			: null

		this.state = {
			selectedDate,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			showTime: false, // Set to true by 2 different datepicking flows. Shows time dropdowns.
			selectedTimeSlot,
			suggestedDate
		}
	}

	componentDidUpdate(prevProps) {
		const { dateProposal, dateProposalLoading } = this.props
		const areDateProposalsReloaded = Boolean(
			prevProps.dateProposalLoading && !dateProposalLoading
		)
		if (areDateProposalsReloaded) {
			if (
				dateProposal &&
				dateProposal.proposals &&
				dateProposal.proposals.length > 0
			) {
				const dateProposalMoments = dateProposal.proposals.map((proposal) =>
					moment.tz(proposal.date, 'Europe/Amsterdam')
				)
				this.setState({
					earliestDateProposal: moment
						.unix(dateProposalMoments[0].unix())
						.tz('Europe/Amsterdam')
				})
			} else {
				this.setState({
					earliestDateProposal: null
				})
			}
		}
	}

	handleChangeSelectedDate = (selectedDate) => {
		const { timeSlots } = this.props
		let selectedTimeFromHour = 7
		let selectedTimeFromMinute = 0
		let selectedTimeTillHour = 17
		let selectedTimeTillMinute = 0
		let selectedTimeSlot = { from: '' }
		// Automatically select time slot if there is only one
		const availableTimeSlotsForSelectedDate = timeSlots.filter(
			(availableDate) => moment(availableDate.from).isSame(selectedDate, 'day')
		)
		if (availableTimeSlotsForSelectedDate.length === 1) {
			const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
			selectedTimeSlot = {
				from: firstTimeSlot.from,
				till: firstTimeSlot.till
			}
			selectedTimeFromHour = moment(firstTimeSlot.from).hour()
			selectedTimeFromMinute = moment(firstTimeSlot.from).minute()
			selectedTimeTillHour = moment(firstTimeSlot.till).hour()
			selectedTimeTillMinute = moment(firstTimeSlot.from).minute()
		}

		this.setState({
			selectedDate,
			datePickerUsed: true,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			selectedTimeSlot
		})
	}

	handleChangeSelectedTimeFromHour = (event) => {
		const { selectedTimeFromMinute, selectedTimeTillHour } = this.state
		const selectedTimeFromHour = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else if (selectedTimeFromHour > selectedTimeTillHour) {
			// Shift the till time by 30 minutes and adjust the till hour if the from time became later than the till time
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: selectedTimeFromHour,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromHour,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else {
			this.setState({
				selectedTimeFromHour
			})
		}
	}

	handleChangeSelectedTimeFromMinute = (event) => {
		const { selectedTimeFromHour, selectedTimeTillHour } = this.state
		const selectedTimeFromMinute = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			if (selectedTimeFromMinute === 0) {
				this.setState({
					selectedTimeFromMinute,
					selectedTimeTillMinute: 30
				})
			} else {
				let nextSelectedTimeTillHour = selectedTimeTillHour + 1
				if (nextSelectedTimeTillHour > 17) {
					nextSelectedTimeTillHour = 17
				}
				const nextSelectedTimeTillMinute = selectedTimeTillHour === 17 ? 30 : 0
				this.setState({
					selectedTimeFromMinute,
					selectedTimeTillHour: nextSelectedTimeTillHour,
					selectedTimeTillMinute: nextSelectedTimeTillMinute
				})
			}
		} else {
			this.setState({
				selectedTimeFromMinute
			})
		}
	}

	handleChangeSelectedTimeTillHour = (event) => {
		const { selectedTimeFromHour } = this.state
		const selectedTimeTillHour = event.target.value
		if (selectedTimeFromHour === selectedTimeTillHour) {
			// Shift the till time by 30 minutes if the from time became the same
			this.setState({
				selectedTimeTillHour,
				selectedTimeTillMinute: 30
			})
		} else {
			this.setState({
				selectedTimeTillHour
			})
		}
	}

	handleChangeSelectedTimeTillMinute = (event) => {
		const selectedTimeTillMinute = event.target.value
		this.setState({
			selectedTimeTillMinute
		})
	}

	handleToggleShowTime = () => {
		const { timeSlots } = this.props
		const { selectedDate, showTime } = this.state
		let selectedTimeFromHour = 7
		let selectedTimeFromMinute = 0
		let selectedTimeTillHour = 17
		let selectedTimeTillMinute = 0
		let selectedTimeSlot = { from: '' }
		// Automatically select time slot if there is only one
		const availableTimeSlotsForSelectedDate = timeSlots.filter(
			(availableDate) => moment(availableDate.from).isSame(selectedDate, 'day')
		)
		if (availableTimeSlotsForSelectedDate.length === 1) {
			const firstTimeSlot = availableTimeSlotsForSelectedDate[0]
			selectedTimeSlot = {
				from: firstTimeSlot.from,
				till: firstTimeSlot.till
			}
			selectedTimeFromHour = moment(firstTimeSlot.from).hour()
			selectedTimeFromMinute = moment(firstTimeSlot.from).minute()
			selectedTimeTillHour = moment(firstTimeSlot.till).hour()
			selectedTimeTillMinute = moment(firstTimeSlot.from).minute()
		}

		this.setState({
			showTime: !showTime,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			selectedTimeSlot
		})
	}

	handleConfirm = () => {
		const { setDeliveryDate } = this.props
		const {
			earliestDateProposal,
			datePickerUsed,
			selectedDate,
			selectedTimeSlot,
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			showTime
		} = this.state

		let formattedEarliestDateProposal
		if (earliestDateProposal) {
			formattedEarliestDateProposal = earliestDateProposal.format()
		}

		const isDatepickerDate = datePickerUsed

		if (selectedDate) {
			if (showTime || (selectedTimeSlot && selectedTimeSlot.from)) {
				// Time selection was made
				const from = selectedDate
					.set({
						hour: selectedTimeFromHour,
						minute: selectedTimeFromMinute,
						second: 0,
						millisecond: 0
					})
					.format()
				const till = selectedDate
					.set({
						hour: selectedTimeTillHour,
						minute: selectedTimeTillMinute,
						second: 0,
						millisecond: 0
					})
					.format()
				setDeliveryDate(
					{ from, till },
					formattedEarliestDateProposal,
					isDatepickerDate
				)
			} else {
				// No time selection
				const from = selectedDate
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.format()
				const till = selectedDate
					.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
					.format()
				setDeliveryDate(
					{ from, till },
					formattedEarliestDateProposal,
					isDatepickerDate
				)
			}
		}
	}

	shouldDisableDate = (date) => {
		const { timeSlots } = this.props
		// Disable date in datepicker if there are no time slots (from Mulesoft) available for that date
		const shouldDisableDate = !timeSlots.some((availableDate) =>
			moment(availableDate.from).isSame(date, 'day')
		)
		return shouldDisableDate
	}

	handleChangeSelectedTimeSlot = (event) => {
		const { timeSlots } = this.props
		const selectedTimeSlotFrom = event.target.value
		const selectedTimeSlotFound = timeSlots.find(
			(timeSlot) => timeSlot.from === selectedTimeSlotFrom
		)

		this.setState({
			selectedTimeSlot: selectedTimeSlotFound,
			selectedTimeFromHour: moment(selectedTimeSlotFound.from).hour(),
			selectedTimeFromMinute: moment(selectedTimeSlotFound.from).minute(),
			selectedTimeTillHour: moment(selectedTimeSlotFound.till).hour(),
			selectedTimeTillMinute: moment(selectedTimeSlotFound.from).minute()
		})
	}

	getTimeInput = () => {
		const {
			selectedTimeFromHour,
			selectedTimeFromMinute,
			selectedTimeTillHour,
			selectedTimeTillMinute,
			showTime
		} = this.state
		const { classes } = this.props

		let timeInput
		if (showTime) {
			let minimumSelectedTimeTillHour =
				selectedTimeFromMinute === 0
					? selectedTimeFromHour
					: selectedTimeFromHour + 1
			const minimumSelectedTimeTillMinute =
				selectedTimeFromHour === selectedTimeTillHour ? 30 : 0
			if (minimumSelectedTimeTillHour > 17) {
				minimumSelectedTimeTillHour = 17
			}
			const selectedTimeFromHourItems = [
				7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
			].map((hour) => (
				<MenuItem key={`from-hour-${hour}`} value={hour}>
					{hour}
				</MenuItem>
			))
			const selectedTimeTillHourItems = [
				7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
			]
				.filter((hour) => hour >= minimumSelectedTimeTillHour)
				.map((hour) => (
					<MenuItem key={`till-hour-${hour}`} value={hour}>
						{hour}
					</MenuItem>
				))
			const selectedTimeFromMinuteItems = [
				{ val: 0, desc: '00' },
				{ val: 30, desc: '30' }
			].map((minute) => (
				<MenuItem key={`from-minute-${minute.val}`} value={minute.val}>
					{minute.desc}
				</MenuItem>
			))
			const selectedTimeTillMinuteItems = [
				{ val: 0, desc: '00' },
				{ val: 30, desc: '30' }
			]
				.filter((minute) => minute.val >= minimumSelectedTimeTillMinute)
				.map((minute) => (
					<MenuItem key={`till-minute-${minute.val}`} value={minute.val}>
						{minute.desc}
					</MenuItem>
				))

			timeInput = (
				<Grid container className={classes.timeSelection}>
					<Grid item xs={6}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" color="primary">
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.from'
									)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Select
									value={selectedTimeFromHour}
									onChange={this.handleChangeSelectedTimeFromHour}
								>
									{selectedTimeFromHourItems}
								</Select>
								<Select
									value={selectedTimeFromMinute}
									onChange={this.handleChangeSelectedTimeFromMinute}
								>
									{selectedTimeFromMinuteItems}
								</Select>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" color="primary">
									{i18n.t(
										'app:appointmentscheduler.Order.Edit.DeliveryDate.till'
									)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Select
									value={selectedTimeTillHour}
									onChange={this.handleChangeSelectedTimeTillHour}
								>
									{selectedTimeTillHourItems}
								</Select>
								<Select
									value={selectedTimeTillMinute}
									onChange={this.handleChangeSelectedTimeTillMinute}
								>
									{selectedTimeTillMinuteItems}
								</Select>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)
		}

		return timeInput
	}

	render() {
		const { classes, dateProposal, dateProposalLoading, timeSlots } = this.props
		const { selectedDate, showTime, selectedTimeSlot } = this.state

		const now = moment.tz('Europe/Amsterdam')

		let disableConfirmButton = false
		if (!now.isSameOrBefore(selectedDate, 'day')) {
			disableConfirmButton = true
		}
		if (!showTime && !selectedTimeSlot.from) {
			disableConfirmButton = true
		}
		if (this.shouldDisableDate(selectedDate)) {
			disableConfirmButton = true
		}

		const CustomDay = (props) => {
			const disabled = this.shouldDisableDate(props.day)
			if (disabled) {
				// render PickersDay disabled if date is not available
				return <PickersDay {...props} disabled={true} />
			}
			const dateProposalFound = dateProposal.proposals.find((proposal) =>
				props.day.isSame(moment.tz(proposal.date, 'Europe/Amsterdam'), 'day')
			)

			if (dateProposalFound !== undefined) {
				let color
				let borderColor
				if (dateProposalFound.rating >= 80 || dateProposalFound.isSuggested) {
					color = colors.green[500]
					if (dateProposalFound.isSuggested) {
						borderColor = colors.green[700]
					}
				} else if (dateProposalFound.rating >= 50) {
					color = colors.orange[500]
				} else if (dateProposalFound.rating >= 25) {
					color = colors.red[500]
				}
				if (!color) {
					// if no rating is found or is lower than 25, render PickersDay without rating
					return <PickersDay {...props} />
				}

				const isSelected = props.selected
				if (isSelected) {
					borderColor = colors.blue[500]
				}

				// if date proposal is found, render PickersDay with rating
				return (
					<PickersDay
						{...props}
						style={{
							backgroundColor: color,
							color: '#fff',
							border: `3px solid ${borderColor}`
						}}
					/>
				)
			}
			// if no date proposal is found, render PickersDay without rating
			return <PickersDay {...props} />
		}

		const datePicker = (
			<DatePicker
				value={selectedDate}
				onChange={this.handleChangeSelectedDate}
				onClose={this.handleCloseDatePicker}
				autoOk={true}
				clearable={false}
				label={i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.label')}
				cancelLabel={i18n.t('app:datepicker.cancel')}
				clearLabel={i18n.t('app:datepicker.clear')}
				format="D MMMM Y"
				minDate={moment.tz(
					{ hour: 0, minute: 0, seconds: 0, milliseconds: 0 },
					'Europe/Amsterdam'
				)}
				disablePast={true}
				minDateMessage={i18n.t(
					'app:appointmentscheduler.Order.Edit.DeliveryDate.minDateErrorText'
				)}
				displayWeekNumber={true}
				shouldDisableDate={this.shouldDisableDate}
				okLabel={i18n.t('app:datepicker.ok')}
				todayLabel={i18n.t('app:datepicker.today')}
				leftArrowIcon={
					<SvgIcon>
						<AngleLeftFal />
					</SvgIcon>
				}
				rightArrowIcon={
					<SvgIcon>
						<AngleRightFal />
					</SvgIcon>
				}
				margin="normal"
				slots={{ day: CustomDay }}
				loading={dateProposalLoading}
				slotProps={{ textField: { fullWidth: true } }}
			/>
		)

		let spinnerDateProposalLoading
		if (dateProposalLoading) {
			spinnerDateProposalLoading = (
				<div className={classes.dateSelectionRow}>
					<CircularProgress size={20} />
					<Typography
						variant="subtitle1"
						className={classes.dateSelectionLoading}
					>
						{i18n.t('app:appointmentscheduler.Order.Edit.DeliveryDate.loading')}
					</Typography>
				</div>
			)
		}
		let timeSlotSelection

		if (timeSlots && timeSlots.length > 0) {
			// Show selection for timeslots
			const availableTimeSlotsForSelectedDate = timeSlots.filter(
				(availableDate) =>
					moment(availableDate.from).isSame(selectedDate, 'day')
			)
			const formattedTimeSlots = availableTimeSlotsForSelectedDate.map(
				(timeSlot) => ({
					from: timeSlot.from,
					fromFormatted: moment(timeSlot.from).format('HH:mm'),
					till: timeSlot.till,
					tillFormatted: moment(timeSlot.till).format('HH:mm')
				})
			)
			const timeSlotItems = formattedTimeSlots.map((timeSlot) => (
				<MenuItem
					key={timeSlot.from}
					value={timeSlot.from}
				>{`${timeSlot.fromFormatted}-${timeSlot.tillFormatted}`}</MenuItem>
			))
			timeSlotItems.unshift(
				<MenuItem key="placeholder" value="placeholder" disabled>
					{i18n.t(
						'app:appointmentscheduler.Order.Edit.DeliveryDate.timeSlotPlaceholder'
					)}
				</MenuItem>
			)

			if (!showTime) {
				timeSlotSelection = (
					<div className={classes.dateSelectionRow}>
						<Grid container type="column" className={classes.timeSelection}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="caption" color="primary">
										{i18n.t(
											'app:appointmentscheduler.Order.Edit.DeliveryDate.timeSlot'
										)}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Select
										value={selectedTimeSlot.from || 'placeholder'}
										onChange={this.handleChangeSelectedTimeSlot}
										fullWidth
										disabled={this.shouldDisableDate(selectedDate)}
									>
										{timeSlotItems}
									</Select>
								</Grid>
							</Grid>
						</Grid>
					</div>
				)
			}
		}

		const specifyOrderTime = (
			<List disablePadding>
				<ListItem button disableGutters onClick={this.handleToggleShowTime}>
					<ListItemText
						primary={i18n.t(
							'app:appointmentscheduler.Order.Edit.DeliveryDate.showTime'
						)}
						primaryTypographyProps={{ variant: 'body2' }}
					/>
					<ListItemSecondaryAction>
						<Switch
							checked={showTime}
							color="primary"
							onChange={this.handleToggleShowTime}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		)

		const timeInput = this.getTimeInput()

		return (
			<div className={classes.root}>
				<div className={classes.dateSelection}>
					{datePicker}
					{spinnerDateProposalLoading}
				</div>
				{timeSlotSelection}
				{specifyOrderTime}
				{timeInput}
				<Button
					color="primary"
					variant="contained"
					disabled={disableConfirmButton}
					onClick={this.handleConfirm}
					className={classes.confirmButton}
				>
					{i18n.t('app:appointmentscheduler.Order.Edit.confirm')}
				</Button>
			</div>
		)
	}
}

export default withStyles(styleSheet)(DeliveryDate)
